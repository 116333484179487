<template>
  <svg
    v-if="name == 'logo'"
    xmlns="http://www.w3.org/2000/svg"
    width="235"
    height="39"
    viewBox="0 0 170 30"
    fill="none"
  >
   <path d="M137.724 15.1813L150.582 30.345H142.614L129.668 15.2165L142.614 0.0175781H150.582L137.724 15.1813Z" fill="#FE0190"/>
<path d="M6.68393 24.7334H12.9809C19.7352 24.7334 21.7755 19.5616 21.7755 15.111C21.7755 9.95673 19.3482 5.50609 12.9809 5.50609H6.68393V24.7334ZM13.7196 0C23.5345 0 28.5474 6.84304 28.5474 15.1989C28.5474 23.9946 23.6752 30.3274 13.0161 30.3274H0V0H13.7196Z" fill="#FE0190"/>
<path d="M46.2949 27.3897C46.5511 30.6305 45.5987 33.8514 43.6213 36.4316C42.2142 38.1908 40.1034 39 36.5856 39H34.8267V33.0716C35.1433 33.0716 35.5478 33.1597 35.8468 33.1597C38.45 33.1597 39.6285 30.9432 39.6285 27.5657V0H46.2949V27.3897Z" fill="#FE0190"/>
<path d="M70.832 6.24487C65.7311 6.24487 62.354 9.92155 62.354 15.3573C62.354 20.793 65.7311 24.4695 70.832 24.4695C75.9329 24.4695 79.31 20.9513 79.31 15.3573C79.31 9.76323 75.9329 6.24487 70.832 6.24487ZM70.832 30.7672C67.7901 30.7811 64.8124 29.8921 62.2758 28.2127C59.7393 26.5334 57.7579 24.1392 56.5826 21.3331C55.4072 18.527 55.0907 15.4352 55.673 12.4492C56.2554 9.46311 57.7104 6.71701 59.854 4.55838C61.9977 2.39974 64.7334 0.925662 67.7151 0.322729C70.6967 -0.280204 73.7902 0.0150773 76.604 1.17126C79.4177 2.32745 81.8252 4.29255 83.5218 6.81779C85.2184 9.34303 86.1277 12.3149 86.1347 15.3573C86.1848 17.3857 85.8241 19.4033 85.0742 21.2886C84.3242 23.174 83.2006 24.888 81.771 26.3277C80.3413 27.7673 78.6352 28.9029 76.7553 29.6659C74.8754 30.4288 72.8605 30.8034 70.832 30.7672Z" fill="#FE0190"/>
<path d="M108.825 6.24487C103.724 6.24487 100.347 9.92155 100.347 15.3573C100.347 20.793 103.724 24.4695 108.825 24.4695C113.926 24.4695 117.303 20.9513 117.303 15.3573C117.303 9.76323 113.926 6.24487 108.825 6.24487ZM108.825 30.7672C105.783 30.7811 102.805 29.8921 100.269 28.2127C97.7322 26.5334 95.7508 24.1392 94.5755 21.3331C93.4001 18.527 93.0836 15.4352 93.6659 12.4492C94.2483 9.46311 95.7034 6.71701 97.847 4.55838C99.9906 2.39974 102.726 0.925662 105.708 0.322729C108.69 -0.280204 111.783 0.0150773 114.597 1.17126C117.411 2.32745 119.818 4.29255 121.515 6.81779C123.211 9.34303 124.121 12.3149 124.128 15.3573C124.178 17.3857 123.817 19.4033 123.067 21.2886C122.317 23.174 121.194 24.888 119.764 26.3277C118.334 27.7673 116.628 28.9029 114.748 29.6659C112.868 30.4288 110.853 30.8034 108.825 30.7672Z" fill="#FE0190"/>
<path d="M166.957 16.1136L156.369 0.0175781H164.46L170.264 9.53448L176.139 0.0175781H184.16L173.641 16.1136V30.345H166.957V16.1136Z" fill="#FE0190"/>
<path d="M221.273 15.1813L234.131 30.345H226.163L213.235 15.2165L226.163 0.0175781H234.131L221.273 15.1813Z" fill="white"/>
<path d="M203.895 15.1813L191.037 30.345H199.005L211.933 15.2165L199.005 0.0175781H191.037L203.895 15.1813Z" fill="white"/>
  </svg>

  <svg
    v-if="name == 'near-logo'"
    xmlns="http://www.w3.org/2000/svg"
    width="76"
    height="20"
    viewBox="0 0 76 20"
    fill="none"
  >
    <path
      d="M38.0449 4.17778V15.8444C38.0449 15.9333 37.9783 16.0222 37.8672 16.0222H36.6449C36.0894 16.0222 35.5561 15.7333 35.2449 15.2667L29.7338 6.75556L29.9116 11V15.8667C29.9116 15.9556 29.8449 16.0444 29.7338 16.0444H28.1338C28.0449 16.0444 27.9561 15.9778 27.9561 15.8667V4.17778C27.9561 4.08889 28.0227 4 28.1338 4H29.3338C29.8894 4 30.4227 4.28889 30.7338 4.75556L36.2449 13.2444L36.0672 9V4.17778C36.0672 4.08889 36.1338 4 36.2449 4H37.8449C37.9783 4 38.0449 4.06667 38.0449 4.17778Z"
      fill="#292839"
    />
    <path
      d="M54.444 16H52.7551C52.644 16 52.5551 15.8889 52.5996 15.7778L57.0885 4.15556C57.1329 4.06667 57.2218 4 57.3107 4H59.444C59.5551 4 59.644 4.06667 59.6663 4.15556L64.1774 15.7778C64.2218 15.8889 64.1329 16 64.0218 16H62.3329C62.2663 16 62.1996 15.9556 62.1774 15.8889L58.5551 6.33333C58.5107 6.2 58.2885 6.2 58.244 6.33333L54.6218 15.8889C54.5774 15.9556 54.5107 16 54.444 16Z"
      fill="#292839"
    />
    <path
      d="M75.9557 15.7333L72.5779 11.4222C74.489 11.0667 75.6001 9.77778 75.6001 7.8C75.6001 5.53333 74.1334 4 71.5112 4H66.8001C66.6668 4 66.5557 4.11111 66.5557 4.24444C66.5557 5.13333 67.2668 5.84444 68.1557 5.84444H71.3334C72.9112 5.84444 73.6668 6.64444 73.6668 7.82222C73.6668 9 72.9334 9.8 71.3334 9.8H66.8445C66.7112 9.8 66.6001 9.91111 66.6001 10.0444V15.8222C66.6001 15.9111 66.6668 16 66.7779 16H68.3779C68.4668 16 68.5557 15.9333 68.5557 15.8222V11.5333H70.4001L73.3334 15.3556C73.6445 15.7778 74.1334 16 74.6668 16H75.889C75.9779 16 76.0445 15.8444 75.9557 15.7333Z"
      fill="#292839"
    />
    <path
      d="M49.3776 4H41.9332C41.7998 4 41.7109 4.08889 41.7109 4.22222C41.7109 5.11111 42.4443 5.84444 43.3332 5.84444H49.3776C49.4665 5.84444 49.5554 5.77778 49.5554 5.66667V4.15556C49.5332 4.06667 49.4665 4 49.3776 4ZM49.3776 14.1556H43.8221C43.7332 14.1556 43.6443 14.0889 43.6443 13.9778V10.9778C43.6443 10.8889 43.7109 10.8 43.8221 10.8H48.9554C49.0443 10.8 49.1332 10.7333 49.1332 10.6222V9.11111C49.1332 9.02222 49.0665 8.93333 48.9554 8.93333H41.9554C41.8221 8.93333 41.7109 9.04444 41.7109 9.17778V15.7333C41.7109 15.8667 41.8221 15.9778 41.9554 15.9778H49.3776C49.4665 15.9778 49.5554 15.9111 49.5554 15.8V14.2889C49.5332 14.2222 49.4665 14.1556 49.3776 14.1556Z"
      fill="#292839"
    />
    <path
      d="M16.0444 1.02222L11.8667 7.22222C11.5778 7.64444 12.1333 8.15556 12.5333 7.8L16.6444 4.22222C16.7556 4.13333 16.9111 4.2 16.9111 4.35556V15.5333C16.9111 15.6889 16.7111 15.7556 16.6222 15.6444L4.17778 0.755556C3.77778 0.266667 3.2 0 2.55556 0H2.11111C0.955556 0 0 0.955556 0 2.13333V17.8667C0 19.0444 0.955556 20 2.13333 20C2.86667 20 3.55556 19.6222 3.95556 18.9778L8.13333 12.7778C8.42222 12.3556 7.86667 11.8444 7.46667 12.2L3.35556 15.7556C3.24444 15.8444 3.08889 15.7778 3.08889 15.6222V4.46667C3.08889 4.31111 3.28889 4.24444 3.37778 4.35556L15.8222 19.2444C16.2222 19.7333 16.8222 20 17.4444 20H17.8889C19.0667 20 20.0222 19.0444 20.0222 17.8667V2.13333C20 0.955556 19.0444 0 17.8667 0C17.1333 0 16.4444 0.377778 16.0444 1.02222Z"
      fill="#292839"
    />
  </svg>

  <svg
    v-if="name == 'arrow-right'"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8223 4.44751L15.3748 9.00001L10.8223 13.5525"
      stroke="#FE0190"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.625 9H15.2475"
      :stroke="'#FE0190' || color"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-if="name == 'plus'"
    :fill="'#FE0190' || color"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="16px"
    height="16px"
    fill-rule="evenodd"
  >
    <path
      fill-rule="evenodd"
      d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
    />
  </svg>
  <svg
    v-if="name == 'near-icon'"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M9.38997 0.949999L7.04262 4.4375C6.88029 4.675 7.19241 4.9625 7.41717 4.7625L9.72709 2.75C9.78958 2.7 9.87695 2.7375 9.87695 2.825V9.11248C9.87695 9.20001 9.76457 9.23752 9.71462 9.17497L2.7224 0.8C2.49765 0.525 2.173 0.375 1.81091 0.375H1.56118C0.911904 0.375 0.375 0.9125 0.375 1.575V10.425C0.375 11.0875 0.911904 11.625 1.57367 11.625C1.98571 11.625 2.37279 11.4125 2.59754 11.05L4.94493 7.56251C5.10725 7.32502 4.7951 7.03748 4.57035 7.2375L2.26041 9.23752C2.19797 9.28747 2.11057 9.25001 2.11057 9.16249V2.8875C2.11057 2.8 2.22295 2.7625 2.27289 2.825L9.26512 11.2C9.48987 11.475 9.827 11.625 10.1766 11.625H10.4264C11.0881 11.625 11.625 11.0875 11.625 10.425V1.575C11.6125 0.9125 11.0756 0.375 10.4139 0.375C10.0018 0.375 9.61472 0.5875 9.38997 0.949999Z"
      fill="#8D9AAD"
    />
  </svg>
</template>

<script>
  export default {
    name: "BaseIcon",

    props: {
      name: {
        type: String,
      },
      color: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped></style>
